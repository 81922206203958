import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'vasTime'
})
export class VasTimePipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 60);
    const minutes = value - hours * 60;
    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }

}
