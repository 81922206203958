<ul class="nav nav-tabs m-2">
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': page === 'form'}" href="#" (click)="open('form')">Pridėti</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [ngClass]="{'active': page === 'history'}" href="#" (click)="open('history')">Istorija</a>
  </li>
</ul>
<ng-container *ngIf="page==='form'">
  <form (submit)="onSubmitForm()" #smsForm="ngForm">
    <div class="form-group">
      <label for="phoneNumber">Telefono Nr.</label>
      <input class="form-control" id="phoneNumber" name="phoneNumber" type="text" [(ngModel)]="phoneNumber" required/>
    </div>
    <div class="form-group">
      <label for="message">Pranešimas</label>
      <textarea id="message" name="message" class="form-control" [(ngModel)]="message" required></textarea>
    </div>
    <div class="actions">
      <button *ngIf="!processing" [disabled]="!smsForm.form.valid" type="submit" class="btn btn-primary">Siųsti</button>
      <button *ngIf="processing" [disabled]="true" type="button" class="btn btn-primary">Siunčiama...</button>
      <span style="margin-left: 1rem;" class="text-success" *ngIf="success">Pranešimas pristatytas sėkmingai.</span>
      <span style="margin-left: 1rem;" class="text-danger" *ngIf="error">Pranešimo pristatyti nepavyko.</span>
    </div>
  </form>
  <div *ngIf="response" class="modal fade show">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div *ngIf="response[0].status === 'INCORRECT'" class="alert alert-danger">
            <span>{{response[0].message}}</span>
          </div>
          <div *ngIf="response[0].status === 'VALID'" class="alert alert-success">
            <pre>{{response[0].message}}</pre>
          </div>
          <div style="text-align: right">
            <button class="btn btn-sm btn-outline-secondary" (click)="response = undefined" type="button">
              <span>Uždaryti</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="page === 'history'">
  <form (submit)="onSubmitHistory()" #historyForm="ngForm">
    <div class="form-group">
      <label for="userId">Naudotojo ID</label>
      <input class="form-control" id="userId" name="userId" type="text" [(ngModel)]="userId" required/>
    </div>
    <div class="form-group">
      <label for="password">Slaptažodis</label>
      <input class="form-control" id="password" name="password" type="text" [(ngModel)]="password" required/>
    </div>
      <div class="actions">
        <button *ngIf="!processing" [disabled]="!historyForm.form.valid" type="submit" class="btn btn-primary">Siųsti
        </button>
        <button *ngIf="processing" [disabled]="true" type="button" class="btn btn-primary">Siunčiama...</button>
        <span style="margin-left: 1rem;" class="text-success" *ngIf="success">Užklausa įvykdyta sėkmingai.</span>
        <span style="margin-left: 1rem;" class="text-danger" *ngIf="error">Užklausos įvykdyti nepavyko.</span>
      </div>
  </form>
  <div *ngIf="report && report.status === 'INCORRECT'" class="alert alert-danger">
    {{report.message}}
  </div>
  <ul class="list-group" *ngIf="report && report.status === 'VALID'">
    <li class="list-group-item" *ngFor="let item of report.records">
      <span class="text-muted">Data: </span>{{item.workFrom | date: 'yyyy-MM-dd'}}<br>
      <span class="text-muted">Darbo laikas: </span>{{item.workMinutes | vasTime}}<br>
      <span class="text-muted">Komentaras: </span>{{item.comment}}<br>
      <span class="text-muted">Įkėlimo data: </span>{{item.created | date: 'yyyy-MM-dd HH:mm'}}<br>
      <span class="text-muted">Projektas: </span>{{item.projectCode}} | {{item.projectTitle}}<br>
    </li>
    <li *ngIf="!report.records?.length" class="list-group list-group-item-info p-3">
      Įrašų nerasta.
    </li>
  </ul>
</ng-container>
